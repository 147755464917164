import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LinkButton from './LinkButton';
import icon from './assets/cmdr.png';
import {
  Container,
  Grid,
  Header,
  Icon,
  Responsive,
  Segment,
  Sidebar,
  Visibility
} from 'semantic-ui-react';

// Heads up!
// We using React Static to prerender our docs with server side rendering, this is a quite simple solution.
// For more advanced usage please check Responsive docs under the "Usage" section.
const getWidth = () => {
  const isSSR = typeof window === 'undefined';

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};

/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */
const HomepageHeading = ({ mobile }) => (
  <Container text>
    <Header
      as="h1"
      content="Commander Pods"
      inverted
      style={{
        fontSize: mobile ? '2em' : '4em',
        fontWeight: 'normal',
        marginBottom: 0,
        marginTop: mobile ? '1.5em' : '1em'
      }}
    />
    <Header
      as="h2"
      content="Create pairings automatically."
      inverted
      style={{
        fontSize: mobile ? '1.5em' : '1.7em',
        fontWeight: 'normal',
        marginTop: mobile ? '0.5em' : '1em'
      }}
    />
    <LinkButton primary size="huge" to="/players/">
      Get Started
      <Icon name="right arrow" />
    </LinkButton>
  </Container>
);

HomepageHeading.propTypes = {
  mobile: PropTypes.bool
};

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  state = {};

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  render() {
    const { children } = this.props;

    return (
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            inverted
            textAlign="center"
            style={{ minHeight: 350, padding: '1em 0em' }}
            vertical
          >
            <HomepageHeading />
          </Segment>
        </Visibility>

        {children}
      </Responsive>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node
};

class MobileContainer extends Component {
  state = {};

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  render() {
    const { children } = this.props;

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Segment
          inverted
          textAlign="center"
          style={{ minHeight: 350, padding: '1em 0em' }}
          vertical
        >
          <HomepageHeading mobile />
        </Segment>

        {children}
      </Responsive>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node
};

const ResponsiveContainer = ({ children }) => (
  <div>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </div>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node
};

const HomepageLayout = () => (
  <ResponsiveContainer>
    <Segment style={{ padding: '8em 0em' }} vertical>
      <Grid container stackable verticalAlign="middle">
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as="h3" style={{ fontSize: '2em' }}>
              MTG Commander / EDH Pairings
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Do you play Commander with a bunch of people? Need to set up pods
              to play and don't want the same people playing each other over and
              over again all night? Use this app to maintain a player list and
              create pairings with a minimal of repeats each night.
            </p>
            <p style={{ fontSize: '1.33em' }}>
              We'll try to create 4-person pods, falling back to a mix of 4 and
              3-person pods if the numbers don't work out. The first round is
              random. Each subsequent round is randomized, but we do our best to
              make sure the same players aren't in pods together. Sometimes
              that's not possible, so you may see some repeats.
            </p>
            <p style={{ fontSize: '1.33em' }}>
              It's fine if new people show up, or if people leave during the
              night. Just hit their Checkmark / X button to signify if they're
              present or not before going to the next round.
            </p>
            <p style={{ fontSize: '1.33em' }}>
              Don't like the pairings? You can regenerate the current round
              without resetting the entire night.
            </p>
            <p style={{ fontSize: '1.33em' }}>
              <b>
                Add this to your homescreen, and it'll work offline.
              </b>
            </p>
          </Grid.Column>
          <Grid.Column width={8}>
            <img src={icon} alt="Commander icon" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  </ResponsiveContainer>
);

export default HomepageLayout;

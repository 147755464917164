import React from 'react';

import G from './assets/G.svg';
import U from './assets/U.svg';
import R from './assets/R.svg';
import B from './assets/B.svg';
import W from './assets/W.svg';
import C from './assets/C.svg';
import BG from './assets/BG.svg';
import BR from './assets/BR.svg';
import GU from './assets/GU.svg';
import GW from './assets/GW.svg';
import RG from './assets/RG.svg';
import RW from './assets/RW.svg';
import UB from './assets/UB.svg';
import UR from './assets/UR.svg';
import WB from './assets/WB.svg';
import WU from './assets/WU.svg';

const images = [G, U, R, B, W, C, BG, BR, GU, GW, RG, RW, UB, UR, WB, WU];


export const PodImage = ({idx}) => <img src={images[idx % images.length]} alt="Pod" />
import React, { PureComponent } from 'react';
import exact from 'prop-types-exact';
import { Card } from 'semantic-ui-react';
import { PodImage } from './PodIcon';
import styled from '@emotion/styled';
import { User } from './User';

const Background = styled.div({
  position: 'absolute',
  left: 5,
  top: 5,
  right: 5,
  bottom: 5,
  '> img': {
    width: '100%',
    height: '100%',
    opacity: '0.25'
  }
});

class Pod extends PureComponent {
  static propTypes = exact({});
  static defaultProps = {};

  render = () => {
    return (
      <Card>
        <Card.Content style={{ minHeight: 100 }}>
          {this.props.pod.map(userId => (
            <User user={this.props.users[userId]} />
          ))}
          <Background>
            <PodImage idx={this.props.idx} />
          </Background>
        </Card.Content>
      </Card>
    );
  };
}

export default Pod;

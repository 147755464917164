import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import exact from 'prop-types-exact';
import { Button, Card } from 'semantic-ui-react';
import styled from '@emotion/styled';

import * as serviceWorker from './serviceWorker';

const Container = styled.div({
  padding: 50
});

class Install extends PureComponent {
  static propTypes = exact({});
  static defaultProps = {};

  install = () => {
    window.deferredPrompt && window.deferredPrompt.prompt();
  };

  update = () => {
    serviceWorker.unregister();
    setTimeout(() => window.location.reload(), 3000)
  };

  render = () => {
    return (
      <Container>
        <Card>
          <Card.Content>
            You might be able to install this to your homescreen to make it
            super-awesome and available offline.
            <br />
          </Card.Content>
          <Card.Content extra>
            <Button onClick={this.install} primary>
              Install
            </Button>
          </Card.Content>
        </Card>

        <Card>
          <Card.Content>
            Check for updates.
            <br />
          </Card.Content>
          <Card.Content extra>
            <Button onClick={this.update} primary>
              Update
            </Button>
          </Card.Content>
        </Card>
      </Container>
    );
  };
}

const mapActionsToProps = {};

const mapStateToProps = (state, ownprops) => ({});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Install);

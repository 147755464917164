import React, { Component } from 'react';
import { withRouter } from 'react-router';

import { Menu } from 'semantic-ui-react';
class TopMenu extends Component {
  static defaultProps = {};
  goTo = location => () => this.props.history.push(location);
  render = () => {
    return (
      <Menu pointing secondary>
        <Menu.Item
          name="Players"
          active={this.props.location.pathname === '/players/'}
          onClick={this.goTo('/players/')}
        />
        <Menu.Item
          name="Pods"
          active={this.props.location.pathname === '/pods'}
          onClick={this.goTo('/pods')}
        />
        <Menu.Item
          name="Past"
          active={this.props.location.pathname === '/history'}
          onClick={this.goTo('/history')}
        />

        {window.deferredPrompt && (
          <Menu.Menu position="right">
            <Menu.Item
              name="Install"
              active={this.props.location.pathname === '/install'}
              onClick={this.goTo('/install')}
            />
          </Menu.Menu>
        )}
      </Menu>
    );
  };
}
export default withRouter(TopMenu);

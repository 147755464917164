import React from 'react';
import './App.css';
import 'semantic-ui-css/semantic.min.css';

import { BrowserRouter as Router, Route } from 'react-router-dom';

import Pods from './Pods';
import UserList from './UserList';

import TopMenu from './TopMenu';
import Install from './Install';
import History from './History';
import { ErrorBoundary } from './ErrorBoundary';
import Intro from './Intro';

window.addEventListener('beforeinstallprompt', e => {
  console.log('beforeinstallprompt');
  // Prevent Chrome 67 and earlier from automatically showing the prompt
  // e.preventDefault();

  // Stash the event so it can be triggered later.
  window.deferredPrompt = e;
});

function App() {
  return (
    <div>
      <Router>
        <TopMenu />

        <Route path="/" exact component={Intro} />

        <Route
          path="/players/"
          exact
          render={props => (
            <ErrorBoundary>
              <UserList {...props} />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/pods/"
          render={props => (
            <ErrorBoundary>
              <Pods {...props} />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/history/"
          render={props => (
            <ErrorBoundary>
              <History {...props} />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/install/"
          render={props => (
            <ErrorBoundary>
              <Install {...props} />
            </ErrorBoundary>
          )}
        />
      </Router>
    </div>
  );
}

export default App;

/*

4 - 4
5 - 3 2
6 - 3 3
7 - 4 3
8 - 4 4
9 - 3 3 3
10 - 4 3 3
11 - 4 4 3
12 - 4 4 4
13 - 4 3 3 3
14 - 4 4 3 3
15 - 4 4 4 3
16 - 4 4 4 4
17 - 4 4 3 3 3
18 - 4 4 4 3 3
19 - 4 4 4 4 3
20 - 4 4 4 4 4
21 - 4 4 4 3 3 3
22 - 4 4 4 4 3 3
23 - 4 4 4 4 4 3
24 - 4 4 4 4 4 4

*/

import shuffle from 'shuffle-array';

export const podCount = num => Math.ceil(num / 4);

// Returns the number times user has been with people in potentialPod before.
// by a power of 2 of the number of times it's happened.
export const pastPodScore = (user, potentialPod, pastPods) => {
  const newPairs = potentialPod.map(a => hashPair(a, user.id));
  const oldPairs = pastPods
    .map(pairs)
    .reduce((acc, curr) => acc.concat(curr), []);

  let score = 0;
  for (const newPair of newPairs) {
    score +=
      oldPairs.filter(p => p === newPair).length *
      oldPairs.filter(p => p === newPair).length *
      5;
  }
  return score;
};

export const findDuplicatePairings = pods => {
  // const userPastPods = pastPods.filter(pod => pod.indexOf(user.id) !== -1);
  // return userPastPods.reduce(
  //   (acc, curr) => acc + countMatches(potentialPod, curr),
  //   0
  // );
  const p = pods.map(pairs).reduce((acc, curr) => acc.concat(curr), []);

  return p.reduce((acc, curr) => {
    acc[curr] = (acc[curr] || 0) + 1;
    return acc;
  }, {});
};

export const duplicateMatches = pods => {
  const tupples = pods.map(pairs).reduce((acc, curr) => acc.concat(curr), []);
  const unique = new Set(tupples);
  return tupples.length - unique.size;
};

export const hashPair = (a, b) => {
  if (String(a).localeCompare(String(b)) > 0) {
    return `${b}-${a}`;
  } else {
    return `${a}-${b}`;
  }
};

export const pairs = pod => {
  if (pod.length < 2) {
    return [];
  }
  const first = pod[0],
    rest = pod.slice(1),
    pairings = rest.map(x => hashPair(x, first));
  return pairings.concat(pairs(rest));
};

const swap = (pod1, pod2, spot1, spot2) => {
  const tmp = pod1[spot1];
  pod1[spot1] = pod2[spot2];
  pod2[spot2] = tmp;
};

export const optimizeOne = (pods, p1, p2, spot1, spot2, pastPods) => {
  if (spot1 >= pods[p1].length) return false;
  if (spot2 >= pods[p2].length) return false;

  const pod1 = pods[p1];
  const pod2 = pods[p2];
  let improved = false;

  let scoreBefore = getDuplicateScores([...pods, ...pastPods]);
  swap(pod1, pod2, spot1, spot2);

  const scoreAfter = getDuplicateScores([...pods, ...pastPods]);
  const c = compareScores(scoreBefore, scoreAfter);
  if (c > 0) {
    // console.log('Good swap', p1, p2, spot1, spot2, scoreBefore, scoreAfter);
    scoreBefore = scoreAfter;
    improved = true;
  } else {
    // console.log('Bad swap', scoreBefore, scoreAfter);
    swap(pod1, pod2, spot1, spot2);
  }
  return improved;
};

export const optimizePods = (result, pastPods) => {
  const pods = result.pods;

  for (let t = 0; t < 50; t++) {
    let improved = false;
    for (let p1 = 0; p1 < pods.length; p1++) {
      for (let spot1 = 0; spot1 < 4; spot1++) {
        for (let p2 = 0; p2 < pods.length; p2++) {
          if (p1 === p2) continue;
          for (let spot2 = 0; spot2 < 4; spot2++) {
            improved =
              improved || optimizeOne(pods, p1, p2, spot1, spot2, pastPods);
          }
        }
      }
    }
    if (!improved) break;
  }

  result.score = getDuplicateScores([...pods, ...pastPods]);
  return result;
};

export const generatePods = (inpUsers, pastPods, round) => {
  let best = null;

  for (let tries = 0; tries < 15; tries++) {
    // Try to get a "perfect" round
    const r = _generatePods(inpUsers, pastPods);

    if (r.score[2] === 0 && r.score[3] === 0 && r.score[4] === 0) {
      // A "perfect" round
      return r;
    }

    if (!best || compareScores(best.score, r.score) > 0) {
      best = r;
    }
  }
  return best;
};

const _generatePods = (inpUsers, pastPods) => {
  const r = __generatePods(inpUsers, pastPods);
  return optimizePods(r, pastPods);
};

const compareScores = (s1, s2) => {
  if (s1[4] !== s2[4]) {
    return s1[4] - s2[4];
  }

  if (s1[3] !== s2[3]) {
    return s1[3] - s2[3];
  }

  if (s1[2] !== s2[2]) {
    return s1[2] - s2[2];
  }

  return 0;
};

export const getDuplicateScores = pods => {
  const pairs = findDuplicatePairings(pods);

  return Object.values(pairs).reduce(
    (acc, curr) => {
      acc[curr] = acc[curr] || 0;
      acc[curr]++;
      return acc;
    },
    {
      5: 0,
      4: 0,
      3: 0,
      2: 0,
      1: 0
    }
  );
};

export const __generatePods = (inpUsers, pastPods) => {
  let users = shuffle(inpUsers);
  const pods = Array(podCount(users.length))
    .fill(true)
    .map(() => []);
  let maxScore = 0;
  const placed = [];

  while (users.length > placed.length) {
    const placedLen = placed.length;
    for (const user of users) {
      if (placed.indexOf(user) !== -1) continue;
      const pod = pods.shift();
      if (pastPodScore(user, pod, pastPods) <= maxScore) {
        // Successful Placement
        pod.push(user.id);
        pods.push(pod);
        placed.push(user);
      } else {
        // No placement
        pods.unshift(pod);
      }
    }
    if (placedLen === placed.length) {
      // If we couldn't place anyone, raise the score.
      maxScore += 0.1;
      users = shuffle(inpUsers);
    }
  }

  return { pods, maxScore };
};

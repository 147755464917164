import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import exact from 'prop-types-exact';
import { getPastRounds, getUserMap } from './reducer';
import { User } from './User';
import styled from '@emotion/styled';

const RoundContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  border: '1px solid #dddddd',
  margin: 5,
  width: '45%',
  boxSizing: 'border-box'
});
const Grid = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  alignItems: 'stretch'
});

const MiniPod = styled.div({
  backgroundColor: '#f0f0f0',
  padding: 5,
  margin: 5,
  minWidth: '45%',
  boxSizing: 'border-box'
});

const Pod = ({ pod, users }) => (
  <MiniPod>
    {pod.map(userId => (
      <User user={users[userId]} />
    ))}
  </MiniPod>
);

const PastRound = ({ round, users }) => (
  <RoundContainer>
    {round.map(pod => (
      <Pod pod={pod} users={users} />
    ))}
  </RoundContainer>
);

class History extends PureComponent {
  static propTypes = exact({});
  static defaultProps = {};

  render = () => {
    return (
      <Grid>
        {this.props.rounds.map((round,idx) => (
          <PastRound round={round} key={idx} users={this.props.users} />
        ))}
      </Grid>
    );
  };
}

const mapActionsToProps = {};

const mapStateToProps = (state, ownprops) => ({
  rounds: getPastRounds(state),
  users: getUserMap(state)
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(History);

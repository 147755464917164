import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore } from 'redux';
import { initial, reducer } from './reducer';

setTimeout(() => {
  if (navigator.storage && navigator.storage.persist)
    navigator.storage.persist().then(granted => {
      if (granted)
        console.log(
          'Storage will not be cleared except by explicit user action'
        );
      else
        console.log('Storage may be cleared by the UA under storage pressure.');
    });
}, 180000);

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return { ...initial, ...JSON.parse(serializedState) };
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch {
    // ignore write errors
  }
};

const store = createStore(reducer, loadState());

store.subscribe(() => {
  saveState(store.getState());
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (window.location.host === 'commander.shittywebapp.com') {
  serviceWorker.register();
} else {
  serviceWorker.unregister();
}

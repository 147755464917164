import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Modal, Menu } from 'semantic-ui-react';
import styled from '@emotion/styled';

import {
  nextRound,
  regenRound,
  resetPods,
  getPods,
  getUserMap,
  getRoundNum
} from './reducer';
import Pod from './Pod';

const Container = styled.div({
  position: 'fixed',
  top: 40,
  left: 5,
  right: 5,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column'
});

const Top = styled.div({
  flexGrow: 1,
  overflowY: 'auto',
  padding: 10
});
const Bottom = styled.div({
  flexGrow: 0,
  height: 50,
  fontSize: 10
});

class Pods extends PureComponent {
  static defaultProps = {};

  // onResetActionClick = (event, data) => {
  //   if(event.currentTarget.textContent === "Reset") {
  //     this.props.resetPods
  //   }
  // };
  render = () => {
    return (
      <Container>
        {this.props.round > 0 && <h1>Round {this.props.round}</h1>}
        <Top>
          {this.props.pods.map((pod, idx) => (
            <Pod key={idx} idx={idx} pod={pod} users={this.props.users} />
          ))}
        </Top>
        <Bottom>
          <Menu>
            {this.props.round === 0 && (
              <Menu.Item name="First Round" onClick={this.props.nextRound} />
            )}

            {this.props.round > 0 && (
              <Modal
                trigger={<Menu.Item name="Next Round" />}
                header={`Go to round ${this.props.round + 1}?`}
                content="This will go to the next round and generate pairings. You can't go back to the previous round."
                actions={[
                  'Cancel',
                  {
                    onClick: this.props.nextRound,
                    key: 'reset',
                    content: 'Next Round',
                    positive: true
                  }
                ]}
                onActionClick={this.onResetActionClick}
              />
            )}
            {this.props.round > 0 && (
              <Modal
                trigger={<Menu.Item name="Regen This Round" />}
                header="Regen this round?"
                content="This will regenerate the pods for this round. This is just if you didn't like the generated pods. If you want to start a new round, use the other button instead."
                actions={[
                  'Cancel',
                  {
                    onClick: this.props.regenRound,
                    key: 'regen',
                    content: 'Regen Round',
                    positive: true
                  }
                ]}
                onActionClick={this.onResetActionClick}
              />
            )}

            <Menu.Menu position="right">
              <Modal
                trigger={<Menu.Item name="Reset Night" />}
                header="Reset The Night?"
                content="This will reset all pairings so you can start a new night. Are you sure?"
                actions={[
                  'Cancel',
                  {
                    onClick: this.props.resetPods,
                    key: 'reset',
                    content: 'Reset',
                    positive: true
                  }
                ]}
                onActionClick={this.onResetActionClick}
              />
            </Menu.Menu>
          </Menu>
        </Bottom>
      </Container>
    );
  };
}

const mapActionsToProps = {
  nextRound,
  regenRound,
  resetPods
};

const mapStateToProps = (state, ownprops) => {
  return {
    pods: getPods(state),
    users: getUserMap(state),
    round: getRoundNum(state)
  };
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Pods);

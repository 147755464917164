import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { getUsers, addUser, modifyUser, removeUser } from './reducer';
import { List } from 'semantic-ui-react';
import styled from '@emotion/styled';
import { Player } from './Player';

const Instructions = styled.h3({
  margin: 50,
  marginTop: 100,
  paddingTop: 100,
  paddingBottom: 100
});

const Container = styled.div({
  position: 'fixed',
  top: 40,
  left: 5,
  right: 5,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column'
});

const Top = styled.div({
  flexGrow: 1,
  overflowY: 'auto',
  padding: 5
});
const Bottom = styled.div({
  flexGrow: 0,
  height: 50
});

class UserList extends PureComponent {
  static defaultProps = {};

  state = {
    tmpName: ''
  };

  addPlayer = () => {
    this.props.addUser(this.state.tmpName);
    this.setState({ tmpName: '' });
  };

  keyDown = e => {
    if (e.keyCode === 13) {
      this.addPlayer();
    }
  };

  toggleUser = user => this.props.modifyUser(user.id, user.name, !user.active);

  tmpNameChange = event =>
    this.setState({ tmpName: event.currentTarget.value });

  render = () => {
    return (
      <Container>
        <Top>
          <List divided verticalAlign="middle">
            {this.props.users && this.props.users.length === 0 && (
              <Instructions>Type player names below.</Instructions>
            )}
            {this.props.users &&
              this.props.users.map((user, idx) => (
                <Player
                  key={idx}
                  onDelete={() => this.props.removeUser(user.id)}
                  onToggle={() => this.toggleUser(user)}
                  user={user}
                />
              ))}
            <List.Item style={{ textAlign: 'right' }}>
              {this.props.users.filter(user => user.active).length} Players
              Selected
            </List.Item>
          </List>
        </Top>
        <Bottom>
          <div className="ui fluid action input">
            <input
              type="text"
              placeholder="Player Name"
              value={this.state.tmpName}
              onChange={this.tmpNameChange}
              onKeyDown={this.keyDown}
            />
            <button onClick={this.addPlayer} className="ui button">
              Add
            </button>
          </div>
        </Bottom>
      </Container>
    );
  };
}

const mapActionsToProps = {
  removeUser,
  addUser,
  modifyUser
};

const mapStateToProps = (state, ownprops) => ({
  users: getUsers(state)
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(UserList);

import uuid from 'uuid/v4';

import { generatePods, getDuplicateScores } from './generate';

export const initial = {
  users: [],
  currentPods: [],
  pastPods: [],
  pastRounds: [],
  round: 0
};

const ADD_USER = 'ADD_USER';
const REMOVE_USER = 'REMOVE_USER';
const MODIFY_USER = 'MODIFY_USER';

const RESET_PODS = 'RESET_PODS';

const NEXT_ROUND = 'NEXT_ROUND';
const REGEN_ROUND = 'REGEN_ROUND';

export const reducer = (state = initial, action = undefined) => {
  switch (action && action.type) {
    case RESET_PODS:
      return {
        ...state,
        round: 0,
        pastRounds: [],
        currentPods: [],
        pastPods: []
      };

    case REGEN_ROUND:
      return {
        ...state,
        currentPods: generatePods(
          state.users.filter(u => u.active),
          state.pastPods,
          state.round
        ).pods
      };

    case NEXT_ROUND:
      const pastRounds = [...state.pastRounds];
      if (state.currentPods && state.currentPods.length > 0) {
        pastRounds.push(state.currentPods);
      }
      const pastPods = [...state.pastPods, ...state.currentPods];
      const round = state.round + 1;
      return {
        ...state,
        pastRounds,
        pastPods,
        round,
        currentPods: generatePods(
          state.users.filter(u => u.active),
          pastPods,
          round
        ).pods
      };

    case ADD_USER:
      return {
        ...state,
        users: [
          ...state.users,
          {
            id: uuid(),
            name: action.payload.name,
            active: true
          }
        ].sort((a, b) => a.name.localeCompare(b.name))
      };

    case REMOVE_USER:
      return {
        ...state,
        users: state.users.filter(user => user.id !== action.payload.id)
      };

    case MODIFY_USER:
      return {
        ...state,
        users: state.users.map(user => {
          if (user.id !== action.payload.id) return user;
          return {
            ...user,
            name: action.payload.name,
            active: action.payload.active
          };
        })
      };

    default:
      return state;
  }
};

export const nextRound = () => ({ type: NEXT_ROUND });
export const regenRound = () => ({ type: REGEN_ROUND });
export const resetPods = () => ({ type: RESET_PODS });

export const getPodScores = state => getDuplicateScores(state.pastPods);
export const getRoundNum = state => state.round;
export const getPods = state => state.currentPods;
export const getPastRounds = state => state.pastRounds;
export const getUserMap = state =>
  state.users.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {});

export const addUser = name => ({ type: ADD_USER, payload: { name } });
export const removeUser = id => ({ type: REMOVE_USER, payload: { id } });
export const modifyUser = (id, name, active) => ({
  type: MODIFY_USER,
  payload: { id, name, active }
});

export const getUsers = state => state.users;

export default reducer;

import React from 'react';
import { Button, List, Modal } from 'semantic-ui-react';
import styled from '@emotion/styled';

const PlayerContainer = styled.div({
  display: 'flex'
});

const Name = styled.div({
  flexGrow: 1,
  width: 100,
  paddingTop: 8,
  paddingLeft: 10
});
export const Player = ({ user, onEdit, onToggle, onDelete, ...props }) => (
  <List.Item>
    <PlayerContainer>
      <Name>{user.name}</Name>

      <Modal
        trigger={
          <Button>
            <i className="trash icon" />
          </Button>
        }
        header={`Delete`}
        content={`Remove ${user.name}?`}
        actions={[
          'Cancel',
          {
            onClick: onDelete,
            key: 'reset',
            content: 'Remove',
            positive: true
          }
        ]}
      />

      <Button onClick={onToggle}>
        {user.active && (
          <i style={{ color: '#00ff00' }} className="check icon" />
        )}
        {user.active || <i style={{ color: '#ff0000' }} className="x icon" />}
      </Button>
    </PlayerContainer>
  </List.Item>
);
